overa-navbar {
  /*NABVAR STYLES*/

  z-index: 10000;
  position: fixed;

  .navbar {
    padding: 0.625rem 0 !important;
  }

  .navbar.navbar-transparent {
    height: 63px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: $navbar-corporate-color !important;
    /*sticky*/
    position: fixed;
    top: 0;
    left: 90px;
    width: calc(100% - 90px);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .navbar-brand {
    display: none;
  }

  .navbar-wrapper .navbar-nav .nav-link {
    background-color: none;
  }

  .navbar .collapse .navbar-nav .nav-item .nav-link,
  .tenant-menu .nav-link,
  .language-menu .nav-link {
    margin-left: 0px !important;
    padding: 5px !important;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    text-transform: initial !important;
    font-size: 14px !important;
    padding: 0.575rem;
  }

  .navbar li > a.nav-link:hover,
  .navbar li .active > a.nav-link,
  .mat-menu-content li > a.nav-link:hover,
  .mat-menu-content li .active > a.nav-link {
    filter: brightness(0) saturate(100%) invert(50%) sepia(68%) saturate(378%)
      hue-rotate(132deg) brightness(83%) contrast(88%) !important;
    cursor: pointer;
  }

  /*END NABVAR STYLES*/
  /*NAVBAR RESPONSIVE STYLES*/
  /*
      @media (max-width: 991px)

       {


          .navbar.navbar-transparent
          {

            box-shadow: none !important;
            background: $navbar-corporate-color;
            height: 73px;
            z-index: 100;
          }


          .div-navbarmobile
          {
            margin-top: 20px !important;
            background-color: transparent !important;
            border: none !important;
          }

          .mat-mdc-button:not(:disabled)
            {
              color: transparent;
            }



      }

      */
  /*END NAVBAR RESPONSIVE STYLES*/
  // }

  .div-navbarmobile {
    display: none;
  }

  .top-nav-responsive {
    display: none;
    z-index: 10001;
    position: relative;
    height: 0;
  }

  .menu_responsive {
    display: none;
  }

  .sidebar-mobile,
  .overa-navbar-mobile {
    display: none;
  }

  /* MEDIA QUERY FOR RESPONSIVE MENU */
  @media only screen and (max-width: 991px) {
    .overa-navbar-mobile {
      .nav-item {
        color: #fff;
      }
    }

    .menu-nomobile {
      display: none !important;
    }

    .sidebar-mobile,
    .overa-navbar-mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .top-nav-responsive {
      display: flex;
      flex-direction: row;
      align-items: center;
      /*justify-content: space-between;*/
      justify-content: flex-end;
      background-color: transparent;
      color: #fff;
      height: 20px;
      padding: 1em;
    }

    .menu_responsive-button-container {
      display: flex;
      height: 30px;
      width: 30px;
      cursor: pointer;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .menu_responsive {
      display: flex;
      position: absolute;
      top: 65px;
      right: 0;
      margin-top: 50px;
      /*left: 0;*/
      flex-direction: column;
      width: 99%;
      justify-content: center;
      align-items: center;
      background-color: #1a75cf;
    }

    .menu_responsive > li {
      margin: 0 1rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0.5em 0;
      width: 100%;
      color: #fff;
      background-color: #fff;
    }

    .menu_responsive > li:not(:last-child) {
      border-bottom: 1px solid #444;
    }

    .menu_responsive-button,
    .menu_responsive-button::before,
    .menu_responsive-button::after {
      display: block;
      background-color: #1a75cf;
      position: absolute;
      height: 4px;
      width: 30px;
      transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
      border-radius: 2px;
    }

    .menu_responsive-button::before {
      content: "";
      margin-top: -8px;
    }

    .menu_responsive-button::after {
      content: "";
      margin-top: 8px;
    }

    #menu_responsive-toggle {
      display: none;
    }

    #menu_responsive-toggle:checked
      + .menu_responsive-button-container
      .menu_responsive-button::before {
      margin-top: 0px;
      transform: rotate(405deg);
    }

    #menu_responsive-toggle:checked
      + .menu_responsive-button-container
      .menu_responsive-button {
      background: rgba(255, 255, 255, 0);
    }

    #menu_responsive-toggle:checked
      + .menu_responsive-button-container
      .menu_responsive-button::after {
      margin-top: 0px;
      transform: rotate(-405deg);
    }

    /* #menu_responsive-toggle ~ .menu_responsive li {
            height: 0;
            margin: 0;
            padding: 0;
            border: 0;
            transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }
*/
    #menu_responsive-toggle ~ .menu_responsive {
      margin: 0;
      padding: 0;
      border: 0;
      display: none;
      //transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    /*#menu_responsive-toggle ~ .menu_responsive .sidebar-mobile {
            margin: 0;
            padding: 0;
            border: 0;
            display: none;
            transition: display 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }*/

    #menu_responsive-toggle:checked ~ .menu_responsive {
      border: 1px solid #333;
      /* height: 2.5em;*/
      padding: 0.5em;
      display: flex;
      flex-direction: column;
    }

    /*
        #menu_responsive-toggle:checked ~ .menu_responsive overa-menubar {
            border: 1px solid #333;
            display: flex;
            padding: 0.5em;
            transition: display 400ms cubic-bezier(0.23, 1, 0.32, 1);
        }*/

    .navbar.navbar-transparent {
      width: 100%;
      left: 0;
    }

    overa-menubar {
      width: 100%;

      .menubar-main-container {
        width: 100%;
      }

      .menubar-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        background-color: white;
      }

      .menubar-item {
        display: flex;

        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  overa-navbar {
    width: 100%;
    left: 0;
    top: 0;
  }
}
