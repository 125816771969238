// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

$primary-color-light: #ffffff; // blanco
$dark-corporate-color: #000;
$primary-color-dark: #000000; // blanco
$primary-corporate-color: #31467f; // azul
$primary-corporate-color-transparent: rgba(49,
    70,
    128,
    0.8); // azul con transparencia
$secondary-corporate-color: #328d8f; // verde
$secondary-corporate-color-2: #416e8e; // variante de verde
$secondary-corporate-color-transparent: rgba(50,
    141,
    143,
    0.7); // verde con transparencia

/// Regular font family
/// @type List
$text-font-stack: "Nunito" !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
  "Monaco", monospace !default;

/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "small": 320px,
  "medium": 768px,
  "large": 1024px,
  ) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'https://cdn.example.com/assets/';
$base-url: "/assets/" !default;

$button_calendar_bgcolor: #D0D0D0;


/*MENU*/
$menu-color-primary: #1d5376;
$menu-color-secundary: #263866;
$menu-color-terciary: #1d2b4e;
$linea-color: #f1f1f1;
$background-linea-color: transparent linear-gradient(180deg, #61a9d1 0%, #263866 100%) 0% 0% no-repeat padding-box;


/*BREADCRUMBS*/

$breadcrumb-left-color: #8D939F;

/*CORPORATE FONTS IN SCSS VARIABLES N*/

$primary-font: Arial,
  Helvetica,
  sans-serif;
$primary-corporate-font: 'Nunito';
$secondary-corporate-font: 'Nexa';

/*icon fonts*/
$primary-corporate-icon-font: 'FontAwesome';

/*END CORPORATE FONTS IN SCSS VARIABLES N*/

/*SIZE CORPORATE FONTS*/

$primary-h4-card-title: 1.125rem;
$primary-text-button: 0.875rem;
$primary-validation-text: 12px;
$primary-login-text: 0.875rem;
$placeholder-primary-text: 14px;
$placeholder-primary-text-small: 13px;
$button-solicitar-oferta-head: 16px;
$kanban-card-size-text: 14px;

$h6-card-title-size-text: 20px;
$table-data-text: 0.875rem;
$breadcrumb-left-text: 16px;

/*OFFER FONTS*/

$offer-data-text: 14px;
$step-offer-highlight-font: 16px;

/*END SIZE CORPORATE FONTS*/

/* CORPORATE COLORS IN SCSS VARIABLES N*/

$primary-color: #263866;
$primary-color-light: #ffffff; // white
$primary-color-dark: #000000; // black
$primary-corporate-color: #263866; // blue
$primary-corporate-color-light: #61a9d1; // blue 2
$primary-corporate-color-transparent: #314680; // transparent blue
$secondary-corporate-color: #ff7a40; // orange 1
$secondary-corporate-color-2: #d84b0f; // orange 2
$secondary-corporate-color-transparent: #ff7a40b3; // orange 4
$gradient-corporate-color: linear-gradient(190deg, #264f66, #263866
); // green fading
$tertiary-color: #61a9d1; //blue
$tertiary-color-error: #d14b42; //red
$quaternary-color: #2f87c9; //blue icon
$label-form-color: #333333; //dark grey

/*text */
$text-color: #3d3636; //grey
$text-error: red;

/*text table colors*/
$header-color: #212121; //grey
$body-td-id-color: #263866; //blue
$body-td-color: #000000; //black
$body-background-newdesign: #e9e9e9; // grey background color

/*input placeholder */
$placeholder-primary-color: #aaaaaa;
$placeholder-readOnly: #e9e9e9;

/*odd and even cell colors*/
$tr-color-child-even: #fafbfb; //odd
$tr-color-child-odd: #f0f8ff; //even
$tr-editting: #aaaaaa; //even

/*navbar*/
$navbar-transparent-color: #eeeeee;
$navbar-corporate-color: #ffffff;

/*sidebar*/
$sidebar-background-color: #1796c1;


/*data-toggle*/
$data-toggle-collapse-color: rgba(255, 255, 255, 0.8);

/*table icons*/
$icon-primary-color: #63605e; //grey
$icon-secondary-color: #263866; //blue
$icon-tertiary-color: #d14b42; //orange
$icon-quaternary-color: #7eba32; //green
$icon-approved-color: #000; //black
$icon-expirationDate: #ff7a40;

/* tabview colors*/
$tabview-color-default: #263866; //blue
$table-header-color: #263866; //blue
$tabview-color-selected: #707070; //grey

/*left menu colors*/
$menu-color-default: #1796c1; //blue

/*kanban colors*/
$kanvan-1-color: #7fc3de; //blue 1
$kanvan-2-color: #85baf3; //blue 2
$kanvan-3-color: #6aa0f2; //blue 3
$kanvan-4-color: #3971c6; // blue 4
$kanvan-linea-step-color: #729f44; //green
$kanvan-text-color: #3d3636; //grey
$kanvan-header-background-color: #1dc6aa;



/*notification colors*/
$notification-color: #d14b42; //orange

/*button colors*/
$button-primary-color: #61a9d1; //blue
$button-secondary-color: #d14b42; //orange
$button-default-color: #87898d; //grey
$button-primary-color-transparent: #314680cc; //blue transparent
$button-secondary-secondary-color-transparent: #ff7a40b3; //orange transparent
$button-text: #ffffff; // white
$button-add: $primary-color; // blue
$button-cancel: #ffeb3b;
$button-cancel-border: #000;
$button-check: #7eba32; //green
$button-save-edit: #61a9d1; //blue
$button-undo: #000000; // black
$button-delete: #d14b42; //orange
/*buttons send & cancel*/
$button-send-primary-background-color: #2b7de9, #005fce;
$button-send-primary-text-color: #fff;

$button-cancel-primary-text-color: #000;
$button-cancel-primary-border-color: #cbcbcb;
$button-cancel-primary-background-color: #ffffff; // white
$button-cancel-primary-background-color-hover: #707070;

/*filter color*/
$filter-primary-color: #61a9d1; //blue

/*button pi-icon*/
$button-pi-color: #d14b42; //orange
$button-color-text: #ffffff; // white
$button-color-disabled: #87898d; //grey

/*link*/
$link-primary-color: $primary-corporate-color; // blue

/* input*/
$input-primary-color-background: #ffffff; // white
$input-primary-border-color: #e6e7e7;
$input-primary-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$input-primary-text-color: #212529;

/*combo*/
$select-primary-border-color: #e6e7e7;
$select-primary-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

/*container box shadow*/
$container-primary-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 14px;
$container-primary-border-color: #e6e7e7;

/*offer colors*/
$step-offer-highlight-color: #eff6ff;
$step-offer-highlight-text-color: #000;

/* TOOLTIP*/
$tooltip-background-color: #cbcbcb;
$tooltip-text-color: #3d3636;

/*SWITCH*/
$switch-checked: $primary-corporate-color; // blue
$switch-background: #314680cc;

/*BUTTON ROUNDED*/

/* END CORPORATE COLORS IN SCSS VARIABLES N*/

/*  CORPORATE BORDERS IN SCSS VARIABLES N*/

/*tabview border*/
$tabview-border-default: 10px 10px 0px 0px;

/*  END CORPORATE BORDERS IN SCSS VARIABLES N*/

/*TEXT COLOR ALERT LOGIN*/
$alert-text-color: #b9b9b9;
/*END TEXT COLOR ALERT LOGIN*/

$warning: $secondary-corporate-color;

/*GREY BACKGROUND*/
$grey-background-color: #ececec;