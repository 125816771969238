.title-steps {
  padding-left: 20px;
}
.title-steps-h4,
.title-inside-steps-h4 {
  color: #6d6e70;
  font-size: 17px;
}
.row-with-title {
  padding-left: 20px;
}
