// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

body.newDesign {



  .container-analysis-patient {

    .truncate-text {
      text-transform: uppercase !important;
      font-weight: bold !important;
      color: black !important;
    }

    .p-calendar-disabled {
      border: none;
    }


  }

  /*fix render file step 2 analysis*/
  .div-render-file a {
    font-size: 14px !important;
  }


  /*botones si no*/

  .styleButtonYesConfirmDialog {
    background-color: #1a75cf !important;
    color: white !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    box-shadow: none !important;
    font-family: "nexa" !important;
    font-size: 15px !important;
    text-transform: uppercase;
    padding: 10px 20px !important;
    border-color: #1a75cf !important;
  }


  .styleButtonNoConfirmDialog,
  .styleButtonNoConfirmDialog:enabled:active {
    background-color: #FF6862 !important;
    color: white !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    box-shadow: none !important;
    font-family: "nexa" !important;
    font-size: 15px !important;
    text-transform: uppercase;
    padding: 10px 20px !important;
    border-color: #FF6862 !important;
  }




  .styleButtonYesConfirmDialog:hover,
  .styleButtonNoConfirmDialog:hover,
  .styleButtonYesConfirmDialog:enabled:active {
    background-color: #176CBF !important;
    border-color: #176CBF !important;
  }

  /*desplegable idioma sin border ni sombra*/
  .menubar-container .p-inputtext {
    border: none !important;
    box-shadow: none !important;
  }

  input {
    border-radius: 5px !important;
  }



  #mat-menu-panel-0 {
    margin-top: 20px;
  }

  /*force input text*/

  .p-inputtext {
    border-radius: 5px !important;
    border: 1px solid #d0d0d0;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    font-family: "Nunito" !important;
  }

  .p-paginator .p-dropdown .p-dropdown-label {
    border: none !important;
  }

  /*end force input text*/

  /*fix panel notice*/

  .panel-notice {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  /*end fix panel notice*/

  /*FIX CARD*/

  overa-card .custom-body,
  overa-card .custom-header {
    cursor: pointer;
  }

  .cards-container .col-12 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .custom-menuOptions {
    margin-top: -12px;
    cursor: pointer;
  }

  .container-level-2 .card {
    box-shadow: none !important;
    margin-top: 5px !important;
  }

  /*END FIX CARD*/

  /*CONTAINER LEVEL 2*/

  .container-level-2 {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    background-color: #ffffff !important;
    width: auto;
    margin-left: 110px;
    margin-right: 20px;
    margin-top: 90px !important;
    padding: 12px;
    padding-bottom: 12px;
    border-radius: 6px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    min-height: 80%;
  }

  @media (max-width: 991px) {
    .container-level-2 {
      margin-left: 10px;
      margin-right: 10px;
      min-height: 90%;
    }
  }

  @media (max-width: 767px) {
    .default-card-header {
      font-size: 15px;
      font-weight: normal;
    }

    .default-card {
      width: 95%;
    }
  }

  /*END CONTAINER LEVEL 2*/

  background-color: #ffffff;
  font-family: $primary-corporate-font;

  .sidebar:hover,
  .sidebar-wrapper {
    width: 80px !important;
    height: calc(100vh);
    background-color: #1a75cf;
  }

  body.newDesign .sidebar-wrapper .nav-link span {
    color: white !important;
  }

  .main-panel .main-content {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sidebarLogo {
    width: -webkit-fill-available;
  }

  .sidebar .logo a.logo-mini {
    opacity: 1;
    float: left;
    width: 30px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    width: -webkit-fill-available;
  }

  overa-tenant-picker,
  overa-language-select {
    align-self: baseline;
  }

  /* .tenant-menu,
    .language-menu {
        padding-left: 5px;
        padding-right: 5px;
    }

    .language-menu{
        width: 70px;
    }

    .tenant-menu > button >span.mat-button-wrapper > span,
    .language-menu > button > span.mat-button-wrapper > span{
        padding-left: 2px;
    } */
  /*color de fondo del menu izquierda normal*/

  a.nav-link {
    /* background: #ED1651 !important;*/
    color: white !important;
    //background-color: #31467f!important;
    transition: all 0.6s ease;
    cursor: pointer !important;
  }

  /*color de fondo del menu izquierda seleccionado*/

  a.nav-link:hover {
    /* background: #ED1651 !important;*/
    color: #338e8f !important;
    //background: rgba(49, 70, 128, 0.7)!important;
    transition: all 0.6s ease;
  }

  /*BREADCRUMBS AIRE Y POSICION DEBAJO MENÚ*/

  .card [class*="card-header-"],
  .main-panel .main-content {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 5px !important;
  }

  .card [class*="card-header-"] .card-text {
    padding: 0px;
    margin-left: 20px;
    margin-top: 15px;
  }

  .row-with-title {
    padding-left: 0px !important;
  }

  /*FIN BREADCRUMBS AIRE Y POSICION DEBAJO MENÚ*/

  .navbar {
    /*padding: 0px !important;*/
    padding: 0.625rem 0 !important;
  }

  .nav .nav-item {
    margin-top: 10px;
    padding-bottom: 15px;
  }

  .justify-content-end {
    margin-right: 4%;
  }

  .navbar.navbar-transparent {
    height: 63px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #ffffff !important;
    //border-bottom: 1px solid grey;
    box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%),
      0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    /*sticky*/
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .navbar-brand {
    display: none;
  }

  .navbar-wrapper .navbar-nav .nav-link {
    background-color: none;
  }

  .logo {
    width: 80px;
  }

  .sidebar .logo {
    /*padding: 8px;*/
    padding: 4px;
    transition: all 0.6s ease;
    background-color: #ffffff;
    height: 63px;
  }

  .sidebar .logo:hover {
    transition: all 0.6s ease;
    -webkit-transform: scale(0.85);
    -moz-transform: scale(0.85);
    -ms-transform: scale(0.85);
    -o-transform: scale(0.85);
    transform: scale(0.85);
    background-color: #ffffff;
  }

  .tab-svg-object {
    width: 100%;
    pointer-events: none;
    display: block;
  }

  .newDesignIcon,
  .newDesignIcon>svg {
    width: 55px;
    height: auto;
    font-size: 50px;
    text-align: center;
  }

  /*Breadcrumb title*/
  .breadcrumb-left {
    font-family: $secondary-corporate-font;
    color: $breadcrumb-left-color;
    font-size: $breadcrumb-left-text;
  }

  h4.card-title {
    /*
    font-family: $secondary-corporate-font;
    color: $breadcrumb-left-color;
    font-size: $breadcrumb-left-text;
    */

    color: #3c4858;
    font-size: 14px !important;
    font-family: "Nexa";
    text-transform: uppercase;
    margin-top: 20px;
  }

  .title-inside-steps-h4 {
    font-family: $secondary-corporate-font;
    color: $breadcrumb-left-color;
    font-size: 13px !important;
  }

  .nav-link span {
    display: inline-block;
  }

  .sidebar .nav-link span {
    color: white;
  }

  .iconMenuItem .newDesignIcon,
  .iconMenuItem svg,
  .tenant-menu svg,
  .tenant-menu .newDesignIcon,
  .language-menu svg,
  .language-menu .newDesignIcon {
    width: 33px;
    height: auto;
  }

  .iconMenuItem svg {
    vertical-align: baseline;
  }

  .menu-nomobile {
    padding-left: 0px;
    margin-left: 0px;
    width: calc(100% - 80px);
  }

  .tenant-menu,
  .language-menu {
    padding-left: 0px;
    padding-right: 0px;
    width: unset !important;
  }

  /*.tenant-menu .mat-icon-button,
    .language-menu .mat-icon-button {
        width: unset !important;
    } */

  #navigation {
    margin-right: 5px;
  }

  .menu-nomobile a.nav-link span,
  .nav-item .sidebar-normal {
    vertical-align: middle;
    color: grey;
  }

  .nav-item .sidebar-normal:hover,
  .nav-item.active .sidebar-normal {
    color: #338e8f;
  }

  /*AJUSTAR SIDEBAR TANTO NORMAL COMO HOVER (YA QUE DESPLIEGA) */

  .nav {
    display: block !important;
  }

  a.nav-link {
    color: $primary-corporate-color !important;
  }

  .navbar .collapse .navbar-nav .nav-item .nav-link,
  .tenant-menu .nav-link,
  .language-menu .nav-link {
    margin-left: 0px !important;
    padding: 5px !important;
  }

  .tenant-menu span {
    text-transform: none;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    text-transform: initial !important;
    font-size: 14px !important;
    padding: 0.575rem;
    margin-left: 20px;
  }

  li>a.nav-link:hover,
  li .active>a.nav-link {
    background: none !important;
    color: $secondary-corporate-color !important;
  }

  .navbar li>a.nav-link:hover,
  .navbar li .active>a.nav-link,
  .mat-menu-content li>a.nav-link:hover,
  .mat-menu-content li .active>a.nav-link {
    filter: brightness(0) saturate(100%) invert(50%) sepia(68%) saturate(378%) hue-rotate(132deg) brightness(83%) contrast(88%) !important;
    cursor: pointer;
  }

  #mat-menu-panel-2 ul.nav {
    margin-top: 40px !important;

    .nav-item.active .sidebar-normal {
      color: #338e8f;
    }
  }

  .sidebar li>a.nav-link {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-left: 1px solid transparent;
    cursor: pointer !important;
  }

  .sidebar li .active>a.nav-link {
    background: transparent !important;
    box-shadow: none !important;
    border-left: 6px solid #318c8e !important;
    border-radius: 0px !important;
  }

  .sidebar li>a.nav-link:hover {
    background: transparent !important;
    border-left: 5px solid white !important;
    border-radius: 0px !important;
  }

  .sidebar li.active>a.nav-link {
    background: transparent !important;
    border-left: 5px solid white !important;
    border-radius: 0px !important;
  }

  .sidebar[data-background-color="rua"] .nav .nav-item.active [data-toggle="collapse"],
  .sidebar[data-background-color="rua"] .nav .nav-item:hover [data-toggle="collapse"] {
    background: transparent !important;
    border-left: 6px solid #318c8e;
    border-radius: 0px !important;
  }

  li>a.nav-link {
    font-size: 14px !important;
    text-transform: initial !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background: none !important;
  }

  .sidebar-mini .sidebar,
  .sidebar-mini:hover .sidebar:hover {
    width: 90px;
    margin-left: 5px;
    text-align: center;
  }

  .sidebar {
    border: 1px solid #e8e8e8;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .sidebar .sidebar-background {
    background: white;
  }

  .sidebar .nav li a {
    margin: 0px auto;
    padding-left: 8px;
    padding-right: inherit;
  }

  .sidebar .logo {
    text-align: center;
    margin: 0 auto !important;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  /*submenu enmarcado sombra*/

  .mat-menu-panel {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  /*FIN ESTILOS CINTA MENU SUPERIOR*/

  /*QUITAR ENMARCADO CONTENEDOR*/

  .main-content .card {
    box-shadow: none !important;
  }

  /*FIN QUITAR ENMARCADO CONTENEDOR*/

  /*ESTILOS BREADCRUMB*/

  .card .card-header-warning .card-text {
    background: none !important;
    box-shadow: none !important;
  }

  .card [class*="card-header-"] .card-text .card-title {
    color: #6d6e70;
    font-size: 14px !important;
    font-family: "Nexa";
  }

  .card-title {
    color: #6d6e70;
    font-size: 14px !important;
    font-family: "Nexa";
    text-transform: uppercase;
  }

  /*FIN ESTILOS BREADCRUMB*/

  /*BOTONERA DEL MENÚ DE LA DERECHA*/

  /*DESPLEGABLES, EJ CERRAR SESIÓN*/
  .dropdown-menu {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .dropdown-menu a,
  .mat-menu-item {
    font-size: 14px !important;
    text-transform: initial !important;
    background: none !important;
    color: $primary-corporate-color !important;
    box-shadow: none !important;
  }

  .dropdown-menu a:hover,
  .mat-menu-item:hover {
    background: none !important;
    color: $secondary-corporate-color !important;
  }

  /*FIN DESPLEGABLES EJ CERRAR SESIÓN*/

  /*BOTONES*/

  /*Desplegable empresa*/
  .tenant-menu>button,
  .language-menu>button,
  a#navbarDropdownMenuLink {
    font-size: 14px !important;
    text-transform: initial !important;
    background: none !important;
    color: $primary-corporate-color !important;
    box-shadow: none !important;
    transition: all 0.6s ease;
  }

  .tenant-menu>button:hover,
  .language-menu>button:hover,
  a#navbarDropdownMenuLink:hover {
    background: none !important;
    color: $secondary-corporate-color !important;
  }

  /*Fin Desplegable empresa*/

  /*CAMBIO COLOR BOTONES DERECHA SVG DE AZUL A VERDE*/

  #navigation .nav-link {
    transition: all 0.6s ease !important;
  }

  #navigation .nav-link:hover,
  #navigation .nav-item.active,
  #navigation .nav-link:focus {
    filter: brightness(0) saturate(100%) invert(50%) sepia(68%) saturate(378%) hue-rotate(132deg) brightness(83%) contrast(88%) !important;
    cursor: pointer;
  }
}

/*AJUSTAR ESTILOS STEPPER (COMOBOS, BOTONES, STEPPER ETC*/

.step-content {
  .p-dropdown-label {
    color: #344767 !important;
    font-family: $primary-corporate-font !important;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
  }

  .p-dropdown-item {
    color: #344767 !important;
    font-size: 15px !important;
  }

  @media (min-width: 991px) {

    /* combos en pantalla grande tamaño minimo */
    .step-1 {
      .p-dropdown {
        border: 1px solid #f0f0f0;
        min-width: 400px !important;
      }
    }

    .sidebar {
      display: block !important;

      .sidebar-wrapper {
        display: none;
      }
    }
  }
}

.multi-steps>li {
  text-align: center !important;
  padding-left: 2% !important;
  padding-right: 2% !important;
  font-family: "nexa";
  font-size: 17px;
}

/*centrar combos y mas pequeños en stepper clase step 1*/
.step-1 {
  .form-step {
    display: flex;
    justify-content: center;
  }

  .container-center {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/*fin centrar combos y mas pequeños en stepper clase step 1*/

/*Boton continuar*/

.btn-continuar {
  background-color: #1a75cf !important;
  font-family: "nexa" !important;
  font-size: 15px !important;
  text-transform: uppercase;
}

.btn-continuar:hover {
  background-color: #176cbf !important;
}

.btn-continuar>i {
  margin-left: 5px;
  margin-right: 5px;
}

.btn-continuar:hover .fa-arrow-right {
  transition: transform 0.5s;
  transform: translate(5px);
}

.btn-continuar:hover .fa-arrow-left {
  transition: transform 0.5s;
  transform: translate(-5px);
}

.btn-continuar .fa-arrow-right,
.btn-continuar .fa-arrow-left {
  transition: transform 0.5s;
  transform: translate(0px);
}

/*boton continuar desactivado*/
.btn-continuar:disabled {
  background-color: #eaeaea !important;
  color: #c9c9c9 !important;
}

/*fin boton continuar*/

/*checkbox si no*/
.onoff label {
  background-color: #ff6862 !important;
  box-shadow: none !important;
}

.onoff input:checked+label {
  background-color: #8cd23e !important;
}

/*fin checkbox si no*/

/*subir adjunto*/

.div-fileupload {
  .download-file a {
    vertical-align: super;
  }
}

.containerStorage label i,
.containerStorage .custom-file-upload {
  color: #1a75cf !important;
}

.containerStorage .truncate-text {
  text-transform: uppercase;
  margin-bottom: 5px;
}

.containerStorage {
  .download-file {
    background-color: #eaeaea !important;
    padding-top: 10px !important;
    /*padding-bottom: 5px !important;*/
  }

  .download-file a {
    font-size: 15px !important;
  }

  .download-file .close {
    background-color: #ff6862;
    color: white;
    border-radius: 6px;
    font-size: 15px;
    /*margin-top: 3px !important;*/
    border: none;
  }

  button.close {
    margin-top: 0px !important;
  }
}

/*fin subir adjunto*/

/*Aviso parte inferior tabla*/

.panel-notice .textTitlePanelNotices {
  background-color: #8cd23e !important;
  color: #ffffff;
  font-family: "Nexa" !important;
  font-size: 15px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: -20px !important;
  margin-left: 50px;
}

.panel-notice .p-fieldset .p-fieldset-legend {
  background-color: white;
  border: none !important;
  padding: 0px;
}

.panel-notice .p-fieldset {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.panel-notice p {
  font-family: "Nunito" !important;
}

/*fin Aviso parte inferior tabla*/

/*redondear botones accion tabla*/

.p-datatable-tbody .p-button {
  color: #ffffff;
  background: #1a75cf;
  border: 1px solid #1a75cf;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.p-datatable-tbody .p-button:hover {
  transform: scale(1.1);
}

/*fin redondear botones accion tabla*/

/*FIN AJUSTAR ESTILOS STEPPER (COMOBOS, BOTONES, STEPPER ETC*/

/*AJUSTAR ESTILOS TABLA*/

/*primera cabecera*/
.p-datatable .p-datatable-header {
  background-color: white;
  border: none;
}

/*segunda cabecera*/

.p-datatable .p-datatable-thead>tr>th {
  background-color: white;
  border-bottom: 2px solid #545999;
}

/*Texto Cabecera*/
.p-datatable .p-datatable-thead>tr>th {
  font-family: "Nexa";
  color: #a8b3c4;
  text-transform: uppercase;
  font-size: 14px !important;
}

/*Texto Contenido*/
.p-datatable .p-datatable-tbody>tr>td {
  font-family: "Nunito";
  color: #344767;
  font-size: 15px;
}

/*color fondo hover encima fila contenido*/

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
  background-color: #fcfcfc !important;
}

/*FIN AJUSTAR ESTILOS TABLA*/

/*AJUSTAR VENTANA FLOTANTE*/


.divContainerButtons {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.5rem;
  margin-top: 30px !important;
  margin-bottom: 20px !important;
}

.p-dialog {
  .p-dialog-title {
    font-size: 15px !important;
  }

  input {
    border-radius: 5px !important;
    height: 40px;
    border: 1px solid #d0d0d0;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  }

  label {
    font-size: 14px;
    line-height: 1.42857;
    color: #344767 !important;
    font-family: "Nexa" !important;
    font-weight: 400;
  }

  .p-dialog-header {
    color: #6d6e70;
    font-family: "Nexa" !important;
    text-transform: uppercase;
    font-size: 14px;
  }

  .mdc-button {
    background-color: #1a75cf !important;
    color: white !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    box-shadow: none !important;
    font-family: "nexa" !important;
    font-size: 15px !important;
    text-transform: uppercase;
    padding: 10px 20px !important;
  }

  .cancel-button-dialog {
    background-color: #ff6862 !important;
  }

  .mdc-button:hover {
    background-color: #176cbf !important;
  }

  .card {
    box-shadow: none;
    border: 1px solid #00beb4;
    padding: 5px;
  }

  .card-title span {
    vertical-align: super;
  }

  .card-header {
    margin-top: 30px !important;
  }

  .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: white !important;
    border-color: transparent;
    background: #1a75cf !important;
    border-radius: 6px !important;
  }

  .p-dialog-header-icon:focus {
    color: #1a75cf !important;
    border-color: transparent;
    background: white !important;
    border-radius: 6px !important;
  }
}

/*FIN AJUSTAR VENTANAS FLOTANTES*/

/*AJUSTAR CABECERA RESPONSIVE CON LOGO */

.row-topnavbarresponsive {
  color: black !important;
}

.aling-left {
  text-align: left;
}

.aling-right {
  text-align: right;
}

.container-image-responsive {
  max-width: 120px !important;
}

.container-logo-responsive {
  width: 100%;
  margin-top: 15px;
}

.menu_responsive-button-container {
  float: right !important;
  margin-top: 15px;
}

/*FIN AJUSTAR VENTANA RESPONSIVE CON LOGO*/

/*AJUSTAR MENU RESPONSIVE SUBMENU*/
.overa-navbar-mobile {
  background-color: white !important;
  margin-top: 20px !important;
  padding: 10px !important;
  border-radius: 10px !important;
  border: 1px solid white !important;
  font-family: "Nunito" !important;
  font-size: 15px !important;
}

/*FIN AJUSTAR MENU RESPONSIVE SUBMENU*/

/*AJUSTAR RESPONSIVE CONTENEDORES Y CARD  RESPONSIVE ALTO*/
@media (max-width: 767px) {
  .container-level-2 {
    padding: 0px !important;
  }

  .filter-text-div {
    justify-content: left !important;
    width: 100% !important;
  }

  .card-container {
    min-height: auto !important;
    margin-top: 10px !important;
  }

  .title-grid {
    max-width: 100% !important;
  }

  .title-view {
    width: 100% !important;
  }

  .card {
    box-shadow: none !important;
  }

  .card-body {
    width: 100% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .card-container {
    max-height: auto !important;
  }

  .default-card {
    label {
      font-family: "Nunito";
      color: #344767;
      font-size: 15px;
    }

    .p-button {
      color: #ffffff;
      background: #1a75cf;
      border: 1px solid #1a75cf;
      border-radius: 6px;
      transition: all 0.3s ease;
    }

    .p-button:hover {
      transform: scale(1.1);
    }
  }
}

/*FIN AJUSTES RESPONSIVE CARD RESPONSIVE ALTO*/

/*AJUSTAR MENU SUPERIOR DERECHA*/




.menubar-main-container {
  .p-button {
    background-color: transparent !important;
    transition: all 0.6s ease !important;
    transform: scale(1) !important;
  }

  .p-button:hover {
    background-color: transparent !important;
    transition: all 0.6s ease;
    transform: scale(1.1) !important;
  }
}

.p-contextmenu .p-menuitem {
  font-family: "Nunito" !important;
}

/*FIN AJUSTAR MENU SUPERIOR DERECHA*/

/*AJUSTAR BUSCAR*/

.search-container {
  background-color: #f0f0f0 !important;
  padding-left: 30px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-radius: 6px !important;
  max-width: 400px;

  input {
    font-family: "Nunito" !important;
    font-size: 16px !important;
    padding-left: 10px;
    height: auto !important;
  }

  .pi-filter-fill {
    color: #545999 !important;
  }
}

.filter-text-div overa-filter-text {
  width: auto !important;
}

/*FIN AJUSTAR BUSCAR*/

/*AJUSTAR PAGINATOR*/

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.p-paginator .p-dropdown {
  border-radius: 6px;
}

/*FIN AJUSTAR PAGINATOR*/

.th-2-3-right {

  th:nth-child(2),
  td:nth-child(2) {
    text-align: right !important;
  }

  th:nth-child(3),
  td:nth-child(3) {
    text-align: right !important;
  }
}

.th-2-right {

  th:nth-child(2),
  td:nth-child(2) {
    text-align: right !important;
  }

  td:nth-child(2) {
    padding-right: 50px !important;
  }
}

/*AJUSTES CALENDARIO*/

.p-calendar-w-btn .p-datepicker-trigger.p-button span {
  color: white !important;
}

.p-calendar-w-btn .p-datepicker-trigger.p-button {
  background-color: #1a75cf !important;
}

.p-paginator-rpp-options .p-inputtext {
  box-shadow: none !important;
}

/*FIN AJUSTES CALENDARIO*/

/*AJUSTES AUTOCOMPLETE*/

.container-autocompleteLaboratoryBranch {
  .p-autocomplete-dropdown.p-button {
    background: white !important;
    color: #a7acb1 !important;
  }

  .p-autocomplete-token {
    border-radius: 6px !important;
  }

  .p-button {
    border: 1px solid #d0d0d0;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px !important;
    border-left: none;
  }
}

/*FIN AJUSTES AUTOCOMPLETE*/

/*AJUSTES VENTANA TIPO ANALISIS CREAR MODIFICAR*/

.type-laboratory {
  .p-dropdown {
    max-width: 300px !important;
  }

  .p-field {
    text-align: center !important;
  }
}

/* FIN AJUSTES VENTANA LTIPO ANALISIS MODIFICAR*/

/*BOTONES VENTANAS FLOTANTES MDC*/

.mdc-button {
  background-color: #1a75cf !important;
  color: white !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease;
  border-radius: 10px !important;
  box-shadow: none !important;
  font-family: "nexa" !important;
  font-size: 15px !important;
  text-transform: uppercase;
  padding: 10px 20px !important;
  margin-left: 20px;
  margin-right: 20px;
  transition: all 0.3s ease !important;

}

/*FIN BOTONES VENTANS FLOTANTES MDC*/

.div-button-collapse {
  button[aria-expanded="false"] .pi-chevron-up {
    display: none;
  }

  button[aria-expanded="false"] .pi-chevron-up {
    display: none;
  }

  button[aria-expanded="false"] .pi-chevron-down {
    display: block;
  }

  button[aria-expanded="false"] .pi-chevron-down {
    display: block;
  }

  button[aria-expanded="true"] .pi-chevron-down {
    display: none;
  }

  button[aria-expanded="true"] .pi-chevron-down {
    display: none;
  }

  button[aria-expanded="true"] .pi-chevron-up {
    display: block;
  }

  button[aria-expanded="true"] .pi-chevron-up {
    display: block;
  }
}