@import "../abstracts/variables";
.filter-text-div {
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 30px;
}
.filter-text-div overa-filter-text {
  width: 75%;
}
.title-view {
  padding-left: 20px;
  min-width: 200px;
  font-family: $secondary-corporate-font;
}
