.containerStorage {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.containerStorage label {
  color: black;
}

.download-file a {
  color: black !important;
  font-size: 14px !important;
}
button.close {
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: normal;
  width: 1.5em;
  margin-top: 0.5em;
}
