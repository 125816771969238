// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-SemiBold.eot');
  src: url('../../assets/fonts/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Nunito-SemiBold.woff2') format('woff2'), url('../../assets/fonts/Nunito-SemiBold.woff') format('woff'), url('../../assets/fonts/Nunito-SemiBold.ttf') format('truetype'), url('../../assets/fonts/Nunito-SemiBold.svg#Nunito-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-Bold.eot');
  src: url('../../assets/fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Nunito-Bold.woff2') format('woff2'), url('../../assets/fonts/Nunito-Bold.woff') format('woff'), url('../../assets/fonts/Nunito-Bold.ttf') format('truetype'), url('../../assets/fonts/Nunito-Bold.svg#Nunito-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-BoldItalic.eot');
  src: url('../../assets/fonts/Nunito-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Nunito-BoldItalic.woff2') format('woff2'), url('../../assets/fonts/Nunito-BoldItalic.woff') format('woff'), url('../../assets/fonts/Nunito-BoldItalic.ttf') format('truetype'), url('../../assets/fonts/Nunito-BoldItalic.svg#Nunito-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}



@font-face {
  font-family: 'Nunito';
  src: url('../../assets/fonts/Nunito-Regular.eot');
  src: url('../../assets/fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/Nunito-Regular.woff2') format('woff2'), url('../../assets/fonts/Nunito-Regular.woff') format('woff'), url('../../assets/fonts/Nunito-Regular.ttf') format('truetype'), url('../../assets/fonts/Nunito-Regular.svg#Nunito-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nexa';
  src: url('../../assets/fonts/NexaBold.eot');
  src: url('../../assets/fonts/NexaBold.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/NexaBold.woff2') format('woff2'), url('../../assets/fonts/NexaBold.woff') format('woff'), url('../../assets/fonts/NexaBold.ttf') format('truetype'), url('../../assets/fonts/NexaBold.svg#NexaBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

